
// web font


// wordpress admin css
// normalize CSS across browsers
import "./src/css/@wordpress/load-styles.css"
import "./src/css/@wordpress/block-library/build-style/style.css"
import "./src/css/normalize.css"

//Custom CSS
import "../assets/css/style.css"

//Global CSS of wordpress.
import "./src/css/gutenberg-global.css"
import "../theme-options-style.css"

//lazyload
import "lazysizes"

//custom js
import "./static/custom.js"
import React from "react"
import { Script, ScriptStrategy } from "gatsby"

// export const shouldUpdateScroll = ({
// 	routerProps: { location },
// 	getSavedScrollPosition,
// }) => {
// 	if (location.pathname === '/property-search') {
// 		const currentPosition = getSavedScrollPosition(location)
// 		setTimeout(() => {
// 			window.scrollTo(...(currentPosition || [0, 0]))
// 		}, 2000);

// 	} else {
// 		window.scrollTo(0, 0)
// 	}

// 	return false
// }

// Logs when the client route changes
// export  const onRouteUpdate = ({ location, prevLocation }) => {
// 	console.log("new pathname", location.pathname)
// 	console.log("old pathname", prevLocation ? prevLocation.pathname : null)
// }

// export const onRouteUpdateDelayed = () => {
// 	console.log("We can show loading indicator now as page took more then 1 seconds")
// }

import wrapWithProvider from "./wrap-with-provider"
export const wrapRootElement = wrapWithProvider

export { wrapPageElement } from "./gatsby-shared"

