import { createSlice } from "@reduxjs/toolkit"

const propertySearchSlice = createSlice({
  name: "property-search",
  initialState: {
    data: {},
    settings: {
      department: "residential-sales",
      page: 1,
      radius: '',
      orderby: "price-desc",
      per_page: 13,
      include_stc: true
    },
    pgp:'',
  },
  reducers: {
    updateSearchState: (state, action) => {
      state.settings = action.payload
    },
    updatePgpPathState: (state, action) => {
			const value = action.payload;
			state.pgp = value
		}
  },
})

export const { updateSearchState, updatePgpPathState } = propertySearchSlice.actions
export default propertySearchSlice.reducer
