window.addEventListener("scroll", fadeIn)

function fadeIn() {
  let elementsArray = document.querySelectorAll(".animate")
  for (var i = 0; i < elementsArray.length; i++) {
    var elem = elementsArray[i]
    var distInView = elem.getBoundingClientRect().top - window.innerHeight + 100
    if (distInView < 0) {
      elem.classList.add("animated")
    } else {
      //elem.classList.remove("animated");
    }
  }
}
fadeIn()
