import { createSlice } from '@reduxjs/toolkit'
const user = createSlice({
	name: 'user',
	initialState: {
		userLocation: {
			address : null,
			// lat: '28.6139391', // Richmond lat, lng
			// lng: '-77.4360481'

			// lat: '28.6139391', // Delhi lat lng
			// lng: '77.2090212'
			
		},
		tenure: null,
		neareastBranchID: null,
	},
	reducers: {
		updateUserLocation: (state, action) => {
			const { page, location} = action.payload;
			// if(['/branch-overview/', '/location-module/'].includes(page) && location) {
			console.log('USER LOCATION UPDATED ON', page, location)
			state.userLocation = location
			// }
		},
		updateUserType: (state, action) => {
			state.tenure = action.payload
			console.log('REDUCER UPDATE USER TYPE', action);
		},
		updateNeareastBranchID: (state, action) => {
			// console.log('UPDATING NEAREAST BRANCH', action.payload);
			state.neareastBranchID = action.payload;
		},
	},
})

export const { updateUserLocation, updateUserType, updateNeareastBranchID } = user.actions

export default user.reducer;

