import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import getDistance from '../../utils/getDistance'

export const getBranch = createAsyncThunk(
	'branch/getBranch',
	async () => {
        const response = await fetch(`${process.env.GATSBY_API_URL}/branch?_fields=id,title,meta.lat,meta.lng,meta.branchImg,meta.branchPhones,meta.branchEmails,link,meta._reapitId&per_page=20`)
                            .then((data) => data.json())
        return response;
	}
)

const branchSlice = createSlice({
    name: 'branch',
    initialState: {
        entities: [],
        loading: false,
        sortOrder : 'title-asc'
    },
    reducers: {
        sortBranch: (state, action) => {
			const {sortOrder, location } = action.payload;
            state.sortOrder = sortOrder
            
            if(sortOrder === 'title-asc'){
                state.entities.sort(function (a, b) {
                    if (a.title.rendered < b.title.rendered) {
                        return -1;
                    }
                    if (a.title.rendered > b.title.rendered) {
                        return 1;
                    }
                    return 0;
                    });
            }

            if(sortOrder === 'title-desc'){
                state.entities.sort(function (a, b) {
                    if (a.title.rendered > b.title.rendered) {
                        return -1;
                    }
                    if (a.title.rendered < b.title.rendered) {
                        return 1;
                    }
                    return 0;
                });
            }

            if(sortOrder === 'distance') {
                if(typeof location  == 'undefined' ) return;
                const { lat: refLat, lng: refLng } = location;
                state.entities.sort((a, b) => {
                    const aDistance = getDistance(a.meta.lat, a.meta.lng, refLat, refLng);
                    a.distance = aDistance;
                    
                    const bDistance = getDistance(b.meta.lat, b.meta.lng, refLat, refLng);
                    b.distance = bDistance;

                    return aDistance - bDistance;
                });



            }
        },
        updateBranch: (state, action) => {
            const branchData = action.payload;
            state.entities = branchData;
        }
        
    },
    extraReducers: {
        [getBranch.pending]: (state) => {
            state.loading = true
        },
        [getBranch.fulfilled]: (state, {payload}) => {
            state.loading = false
            state.entities = payload
        },
        [getBranch.rejected]: (state) => {
            state.loading = false
        },
    },
})


export const { sortBranch, updateBranch } = branchSlice.actions

export default branchSlice.reducer;
